import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Urls} from "../utils/urls";
import {MessagingService} from "./messaging.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  user: any;
  token: string;
  isAdminLogin = false;
  remember;
  _id;
  organizationId;
  isiPad = false;
  role: any;
  profilePicture;
  constructor(private http: HttpClient, private messagingService: MessagingService, private router: Router,) {
    const u = localStorage.getItem('user');
    this.user = u ? u : null;
    const t = localStorage.getItem('token');
    this.token = t ? t : null;
    const r = localStorage.getItem("remember");
    this.remember = r ? r : false;
    const o = sessionStorage.getItem("organizationId");
    this.organizationId = o ? o : null;
    const p = localStorage.getItem("profile");
    this.profilePicture = p ? p : null;
  }

  login(creds) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.login, creds)
        .subscribe((res: any) => {
          if (res.role !== 'superAdmin') {
            this.token = res['token'];
            this.user = res.name;
            this._id = res._id;
            this.role = res.role;
            this.organizationId = res.organizationId;
            this.profilePicture = res.profilePicture;
            if (this.getRememberMe()) {
              localStorage.setItem('role', this.role);
              localStorage.setItem('token', this.token);
              localStorage.setItem('user', this.user);
              localStorage.setItem('fcmToken', this.messagingService.fcmToken);
              localStorage.setItem('organizationId', this.organizationId);
              localStorage.setItem('_id', this._id);
              localStorage.setItem("profile", this.profilePicture);
            } else {
              sessionStorage.setItem('role', this.role);
              sessionStorage.setItem('token', this.token);
              sessionStorage.setItem('user', this.user);
              sessionStorage.setItem('fcmToken', this.messagingService.fcmToken);
              sessionStorage.setItem('organizationId', this.organizationId);
              sessionStorage.setItem('_id', this._id);
              sessionStorage.setItem("profile", this.profilePicture);
            }
            resolve(res)
          } else {
            reject({
              error: {
                message: 'To access the portal, please login with help of Super Admin Panel.'
              }
            })
          }

        }, err => {
          reject(err)
        });
    });
  }

  loginAsSuperAdmin(creds) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.superAdminLogin, creds)
        .subscribe((res: any) => {
          sessionStorage.setItem('fcmToken', this.messagingService.fcmToken);
          resolve(res)
        }, err => {
          reject(err)
        });
    });
  }

  changePassword(data) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.changePassword, data).subscribe(
        (res: any) => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }


  logout() {
    return new Promise((resolve, reject) => {
      const fcmToken = {
        fcmToken: this.messagingService.fcmToken
      };
      this.http
        .put(Urls.logout, fcmToken, {headers: {authorization: this.getToken()}})
        .subscribe(
          res => {
            if (this.getRememberMe()) {
              localStorage.removeItem("role");
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              localStorage.removeItem("fcmToken");
              localStorage.removeItem("organizationId");
              localStorage.removeItem("_id");
              localStorage.removeItem("profile");
            } else {
              sessionStorage.removeItem("role");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("user");
              sessionStorage.removeItem("fcmToken");
              sessionStorage.removeItem("organizationId");
              sessionStorage.removeItem("_id");
              sessionStorage.removeItem("profile");
            }
            if(this.isAdminLogin){
              sessionStorage.removeItem("role");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("user");
              sessionStorage.removeItem("fcmToken");
              sessionStorage.removeItem("organizationId");
              sessionStorage.removeItem("_id");
              sessionStorage.removeItem("profile");
            }
            this.token = "";
            resolve(res);
          },
          err => {
            if (err.hasOwnProperty('error')) {
              console.log(err);
              if (err.error.statusCode === 401) {
                if (this.getRememberMe()) {
                  localStorage.removeItem("role");
                  localStorage.removeItem("token");
                  localStorage.removeItem("user");
                  localStorage.removeItem("fcmToken");
                  localStorage.removeItem("organizationId");
                  localStorage.removeItem("_id");
                  localStorage.removeItem("profile");
                } else {
                  sessionStorage.removeItem('role');
                  sessionStorage.removeItem("token");
                  sessionStorage.removeItem("user");
                  sessionStorage.removeItem("fcmToken");
                  sessionStorage.removeItem("organizationId");
                  sessionStorage.removeItem("_id");
                  sessionStorage.removeItem("profile");
                }
                this.router.navigate(["login"]);
                this.token = "";
              }
            }
            reject(err);
          }
        );
    });
  }

  getToken() {
    if (!this.isAdminLogin) {
      if (this.getRememberMe()) {
        return localStorage.getItem("token");
      } else {
        return sessionStorage.getItem("token");
      }
    } else {
      return sessionStorage.getItem("token");
    }
  }

  getId() {
    if (!this.isAdminLogin) {
      if (this.getRememberMe()) {
        return localStorage.getItem("_id");
      } else {
        return sessionStorage.getItem("_id");
      }
    } else {
      return sessionStorage.getItem("_id");
    }
  }


  getUserName() {
    if (!this.isAdminLogin) {
      if (this.getRememberMe()) {
        return localStorage.getItem("user");
      } else {
        return sessionStorage.getItem("user");
      }
    } else {
      return sessionStorage.getItem("user");
    }
  }

  getUserRole() {
    if (this.getRememberMe()) {
      return localStorage.getItem("role");
    } else {
      return sessionStorage.getItem("role");
    }
  }

  getFCMToken() {

    if (this.getRememberMe()) {
      return localStorage.getItem("fcmToken");
    } else {
      return sessionStorage.getItem("fcmToken");
    }
  }

  getOrganizationId() {
    if (!this.isAdminLogin) {
      if (this.getRememberMe()) {
        return localStorage.getItem("organizationId");
      } else {
        return sessionStorage.getItem("organizationId");
      }
    } else {
      return sessionStorage.getItem("organizationId");
    }
  }

  setRememberMe(val) {
    localStorage.setItem("remember", val);
  }

  getRememberMe() {
    return JSON.parse(localStorage.getItem("remember"));
  }

  forgetPassword(data) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.forgotPassword, data).subscribe(
        (res: any) => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  setPassword(data, id) {
    return new Promise((resolve, reject) => {
      return this.http
        .post(Urls.setPassword + id + "/set-password", data)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  resetPassword(data, id) {
    return new Promise((resolve, reject) => {
      return this.http
        .post(Urls.resetPassword + id + "/reset-password", data)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  setUserProfile(profile) {
    if (this.getRememberMe()) {
      localStorage.setItem("profile", profile);
    } else {
      sessionStorage.setItem("profile", profile);
    }
  }

  setUserName(name) {
    if (this.getRememberMe()) {
      localStorage.setItem("user", name);
    } else {
      sessionStorage.setItem("user", name);
    }
  }
  getUserProfile() {
    if (this.getRememberMe()) {
      return localStorage.getItem("profile");
    } else {
      return sessionStorage.getItem("profile");
    }
  }
}
