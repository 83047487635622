export const environment = {
      // Gradlesol Dev
      // production: false,
      // apiUrl: 'https://1158-103-240-208-228.ngrok.io/',
      // secret: 'RtF7eyoIQR4.r-V3bwxETPekeZY5B8fkusMvkvDFYe2B2pSYDGXxVfU',
      // firebase: {
      //   apiKey: "AIzaSyCJJQacPC9Zu2FTpZ2ueof82zhXaws1h_4",
      //   authDomain: "fir-project-niti.firebaseapp.com",
      //   databaseURL: "https://fir-project-niti.firebaseio.com",
      //   projectId: "fir-project-niti",
      //   storageBucket: "fir-project-niti.appspot.com",
      //   messagingSenderId: "65982489994",
      //   appId: "1:65982489994:web:5c0f03d0b9934365c49473"
      // }

      // Botcore Dev
      // production: false,
      // apiUrl: 'https://admin.botcore.dev.humanpixel.com.au/app/',
      // secret: 'x4lhOKp4ECY.bALwZqzRTUY1j7VU9-1GJSY7yqz5Q6AQDuz5FvA__xw',
      // firebase: {
      //   apiKey: "AIzaSyBTtJ3_k2c3zRQxw1PzVTY7lxrjnt2tp7s",
      //   authDomain: "chat-dev-botcore.firebaseapp.com",
      //   databaseURL: "https://chat-dev-botcore.firebaseio.com",
      //   projectId: "chat-dev-botcore",
      //   storageBucket: "chat-dev-botcore.appspot.com",
      //   messagingSenderId: "716198790707",
      //   appId: "1:716198790707:web:ff81ca05502fdb9f2c0546",
      //   measurementId: "G-227HK8G00X"
      // }

      // Botcore Prod
      production: false,
      apiUrl: 'https://admin.botcore.humanpixel.com.au/app/',
      secret: 'mbjvbwe_xjU.QFZJpPmmlL0W9ggQ3lz0DOK1TfOBSVAAi0wDO8vBr7I',
      firebase: {
        apiKey: "AIzaSyDSTGWxePhxjBG4jqXb2R6xsaLCteHhrFs",
        authDomain: "chat-botcore.firebaseapp.com",
        databaseURL: "https://chat-botcore.firebaseio.com",
        projectId: "chat-botcore",
        storageBucket: "chat-botcore.appspot.com",
        messagingSenderId: "375788809938",
        appId: "1:375788809938:web:5a25343860777302a1470b",
      }



};

