import {Injectable} from '@angular/core';

import 'rxjs/add/operator/take';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {take} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  fcmToken;
  unreadMessage = 0;
  notificationCount = 0;
  unreadMessageSubject = new Subject();
  tokenSubject = new Subject();

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging, private snackBar: MatSnackBar) {
    this.angularFireMessaging.messaging.subscribe(
      (messaging) => {
        messaging.onMessage = messaging.onMessage.bind(messaging);
        messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      }
    );
  }

  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token;
        this.angularFireDB.object('fcmTokens/').update(data);
      });
  }

  requestPermission(userId) {
    return new Promise((resolve, reject) => {
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {

          this.tokenSubject.next(true);
          this.fcmToken = token;
          this.updateToken(userId, token);
          resolve(token);
        },
        (err) => {
          this.tokenSubject.next(false);
          console.error('Unable to get permission to notify.', err);
          reject(err);
        }
      );
    });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        this.notificationCount++;
        const notifData = JSON.parse(payload.data.payload);
        if (notifData.isWaiting) {
          this.snackBar.open('New user request received', '', {
            horizontalPosition: 'right',
            duration: 3000,
            panelClass: ['snack-success']
          });
        }
        this.currentMessage.next(payload);
      });
  }
}
