import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {AccountService} from "../../services/account.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HandoffService} from "../../services/handoff.service";
import {MessagingService} from "../../services/messaging.service";
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {$e} from 'codelyzer/angular/styles/chars';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material';
import {ModuleAccessService} from "../../services/module-access.service";

declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit ,OnDestroy{
  loginForm: FormGroup;
  setPassForm: FormGroup;
  resetPassForm: FormGroup;
  formStatus = 'login';
  remember;
  id;
  token;
  isLoadingMain = false;
  forgetPassForm: FormGroup;
  unSubscribe = new Subject();
  adminData={};
  hide=true;
  dialogRef;
  moduleAccess = {
    analytics: {
      mainModule: false,
      chatWithCustomerSupport: false,
      agentSpecificRequests: false,
      partOfTimeRequestArrived: false,
      mostCommunicatedHours: false,
      totalUsers: false,
      topFirstIntents: false,
      topResolutionIntents: false,
      geographic: false,
      age: false,
      initialPage: false,
      lastUrlClicked: false,
      downloadAnalytics: false,
      tagAnalytics: false,
    },
    webWidget: {
      mainModule: false,
      delay: false,
      welcomeMessage: false,
      customiseLookAndFeel: false
    },
    botSettings: {
      mainModule: false,
      messageConfiguration: false,
      integration: false,
      general: false,
      user: false
    },
    support: {
      mainModule: false,
      viewChatsWithBot: false,
      communicateWithAgent: false,
      agentToAgentTransfer: false,
      requestScreenshot: false,
      sendIntentAsResponse: false
    },
    customTag: {
      mainModule: false,
      tagCreate: false,
      tagUpdate: false,
    }
  };
  @ViewChild("templateRef",{static:false})templateRef: TemplateRef<any>;

  constructor(private formBuild: FormBuilder,
              private accountService: AccountService,
              private http: HttpClient,
              private router: Router,
              public dialog: MatDialog,
              private route: ActivatedRoute, private snackBar: MatSnackBar,
              private messagingService: MessagingService,
              private accessService: ModuleAccessService) {
    this.loginForm = this.formBuild.group({
      'userId': ['', [Validators.required, Validators.email]],
      'password': ['', Validators.required],
      'rememberMe': false,
    });

    this.setPassForm = this.formBuild.group({
      'setPassword': ['', Validators.required],
      'confirmPassword': ['', Validators.required]
    });

    this.resetPassForm = this.formBuild.group({
      'newPassword': ['', Validators.required],
      'confirmPassword': ['', Validators.required]
    });

    this.forgetPassForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email])
    });


  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        if(res.organizationId){
          this.accountService.isAdminLogin=true;
          sessionStorage.setItem('_id',  res.userId);
          sessionStorage.setItem('role', 'superAdmin');
          sessionStorage.setItem('token', res.token);
          sessionStorage.setItem('user', res.userName);
          sessionStorage.setItem('organizationId', res.organizationId);
          this.accountService.organizationId=res.organizationId;
          this.accountService.role='superAdmin';
          this.accountService.token=res.token;
          this.accountService.user=res.userName;
          this.accountService._id=res.userId;
          this.adminData=res;
          this.isLoadingMain = true;
        }
      });
    const userId = 'user001' + Date.now();
    /*Get fcm Token from firebase*/
    this.messagingService.requestPermission(userId).then(res => {
      if(this.accountService.isAdminLogin){
        this.accountService.loginAsSuperAdmin({organizationId:this.accountService.organizationId,fcmToken:this.messagingService.fcmToken}).then(res => {
        }).catch(err => {
          this.snackBar.open(err.error.message ? err.error.message : 'Something went wrong', '', {
            horizontalPosition: 'right',
            duration: 3000,
            panelClass: ["snack-error"]
          })
        });
      }
    }).catch(err => {
      this.snackBar.open(err.error.message ? err.error.message : 'Something went wrong', '', {
        horizontalPosition: 'right',
        duration: 3000,
        panelClass: ["snack-error"]
      })
    });
    this.messagingService.receiveMessage();
    $('#initial_loader').remove();
    if (this.accountService.getUserName() && this.accountService.getToken()) {
      this.isLoadingMain = true;
      this.router.navigate(["dashboard"]);
    }
    if (this.router.url.includes("/set")) {
      this.formStatus = "setPassword";
    }
    if (this.router.url.includes("/reset")) {
      this.formStatus = "resetPassword";
    }
    this.messagingService.tokenSubject.subscribe((res) => {
      if(!res){
        if(!this.accountService.isAdminLogin){
          setTimeout(()=>{
            this.dialogRef = this.dialog.open(DialogComponent, {
              panelClass: 'addUser',
              data: {title: "Allow Notification", template: this.templateRef, isShowSave: false},
              height: 'auto',
              width: '500px'
            });
          },0);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe.complete();
  }

  doLogin() {
    if (this.loginForm.valid) {
      if (this.loginForm.value.password.trim()) {
        const data = {
          userId: this.loginForm.value.userId.toLowerCase(),
          password: this.loginForm.value.password,
          fcmToken: this.messagingService.fcmToken
        };
        this.accountService.setRememberMe(this.loginForm.value.rememberMe);
        this.accountService.login(data).then(async res => {
          this.isLoadingMain = true;
          this.moduleAccess = await this.accessService.getModules();
          const userRole = this.accountService.getUserRole();
          if (userRole === 'supportAgent' && this.moduleAccess.support.mainModule) {
            this.router.navigate(['dashboard','hand-off'], {
              queryParams: {
                status: 'newRequest',
              }
            });
          }
          if (this.moduleAccess.analytics.mainModule) {
              this.router.navigate(['dashboard']);
          } else if (this.moduleAccess.support.mainModule) {
            this.router.navigate(['dashboard','hand-off'], {
              queryParams: {
                status: 'newRequest',
              }
            });
          } else if (this.moduleAccess.webWidget.mainModule) {
            this.router.navigate(['dashboard','web-widget']);
          } else if (this.moduleAccess.botSettings.mainModule) {
            this.router.navigate(['dashboard','bot-settings']);
          } else if (this.moduleAccess.customTag.mainModule) {
            this.router.navigate(['dashboard','tag']);
          }

        }).catch(err => {
          this.snackBar.open(err.error.message ? err.error.message : 'Something went wrong', '', {
            horizontalPosition: 'right',
            duration: 3000,
            panelClass: ["snack-error"]
          })
        });
      }
    } else {
      this.snackBar.open("Please enter all the required details.", "", {
        horizontalPosition: "right",
        duration: 3000,
      });
    }
  }

  toggleRemember(event) {
    this.remember = event.checked;

  }

  doSetPassword() {
    if (this.setPassForm.valid) {
      if (this.setPassForm.controls["setPassword"].value.trim() === '') {
        this.snackBar.open("Please enter valid new password", "", {
          horizontalPosition: "right",
          duration: 3000,
          panelClass: ["snack-error"]
        });
        return;
      }
      if (this.setPassForm.controls["confirmPassword"].value.trim() === '') {
        this.snackBar.open("Please enter valid confirm password", "", {
          horizontalPosition: "right",
          duration: 3000,
          panelClass: ["snack-error"]
        });
        return;
      }
      if (
        this.setPassForm.controls["setPassword"].value !==
        this.setPassForm.controls["confirmPassword"].value
      ) {
        this.snackBar.open(
          "password and confirm password does not match.",
          "",
          {
            horizontalPosition: "right",
            duration: 3000,
            panelClass: ["snack-error"]
          }
        );
        return;
      }
      this.id = this.route.snapshot.params.id;
      this.route.queryParams.subscribe(value => (this.token = value.token));
      console.log(this.token);
      const data = {
        signUpToken: this.token,
        password: this.setPassForm.controls["setPassword"].value
      };
      this.accountService
        .setPassword(data, this.id)
        .then((res: any) => {
          this.snackBar.open("Password set successfully.", "", {
            horizontalPosition: "right",
            duration: 3000,
            panelClass: ["snack-success"]
          });
        })
        .catch(err => {
          this.snackBar.open(
            err.error && err.error.message
              ? err.error.message
              : "Something went wrong.",
            "",
            {
              horizontalPosition: "right",
              duration: 3000,
              panelClass: ["snack-error"]
            }
          );
        });
    } else {
      this.snackBar.open("Please enter all fields.", "", {
        horizontalPosition: "right",
        duration: 3000,
        panelClass: ["snack-error"]
      });
    }
    this.formStatus = "login";
  }

  doResetPassword() {
    if (this.resetPassForm.valid) {
      if (this.resetPassForm.controls["newPassword"].value.trim() === '') {
        this.snackBar.open("Please enter valid new password", "", {
          horizontalPosition: "right",
          duration: 3000,
          panelClass: ["snack-error"]
        });
        return;
      }
      if (this.resetPassForm.controls["confirmPassword"].value.trim() === '') {
        this.snackBar.open("Please enter valid confirm password", "", {
          horizontalPosition: "right",
          duration: 3000,
          panelClass: ["snack-error"]
        });
        return;
      }
      if (
        this.resetPassForm.controls["newPassword"].value !==
        this.resetPassForm.controls["confirmPassword"].value
      ) {
        this.snackBar.open(
          "New password and confirm password does not match.",
          "",
          {
            horizontalPosition: "right",
            duration: 3000,
            panelClass: ["snack-error"]
          }
        );
        return;
      }
      this.id = this.route.snapshot.params.id;
      this.route.queryParams.subscribe(value => (this.token = value.token));
      console.log(this.token);
      const data = {
        token: this.token,
        password: this.resetPassForm.controls["newPassword"].value
      };
      this.accountService
        .resetPassword(data, this.id)
        .then((res: any) => {
          this.snackBar.open("Password reset successfully.", "", {
            horizontalPosition: "right",
            duration: 3000,
            panelClass: ["snack-success"]
          });
        })
        .catch(err => {
          this.snackBar.open(
            err.error && err.error.message
              ? err.error.message
              : "Something went wrong.",
            "",
            {
              horizontalPosition: "right",
              duration: 3000,
              panelClass: ["snack-error"]
            }
          );
        });
    } else {
      this.snackBar.open("Please enter all fields.", "", {
        horizontalPosition: "right",
        duration: 3000,
        panelClass: ["snack-error"]
      });
    }
    this.formStatus = "login";
  }

  forgotPass() {
    this.formStatus = "forgotPassword";
    if ((this.formStatus = "forgotPassword")) {
      this.forgetPassForm.controls["email"].setValue(
        this.loginForm.controls["userId"].value
      );
    }
  }

  backToLogin() {
    this.formStatus = "login";
  }

  sendEmail() {
    if (this.forgetPassForm.valid) {
      let data = {
        email: this.forgetPassForm.controls["email"].value.toLowerCase()
      };
      this.accountService
        .forgetPassword(data)
        .then((res: any) => {
          this.snackBar.open(
            "Reset password link has been sent to your email address.",
            "",
            {
              horizontalPosition: "right",
              duration: 3000,
              panelClass: ["snack-success"]
            }
          );
        })
        .catch(err => {
          this.snackBar.open(
            err.error.message ? err.error.message : "Something went wrong.",
            "",
            {
              horizontalPosition: "right",
              duration: 3000,
              panelClass: ["snack-error"]
            }
          );
        });
    } else {
      this.snackBar.open("Please enter email address.", "", {
        horizontalPosition: "right",
        duration: 3000,
        panelClass: ["snack-error"]
      });
    }
    this.formStatus = "login";
  }

  handleEnter($event: any,save?) {
    this.hide=!this.hide;
    if(save==='setPassword'){
      this.doSetPassword()
    }else if(save==='doLogin'){
      this.doLogin();
    }else if(save==='resetPassword'){
      this.doResetPassword();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
