import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Urls} from '../utils/urls';
import {AccountService} from './account.service';

@Injectable({
  providedIn: 'root'
})
export class HandoffService {

  constructor(private http: HttpClient, private accountService: AccountService) {

  }

  getIntents() {
    return new Promise((resolve, reject) => {
        return this.http.get(Urls.intent + '?organizationId=' + this.accountService.getOrganizationId())
          .subscribe((res: any) => {
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    );
  }
  getAgents() {
    return new Promise((resolve, reject) => {
        return this.http.get(Urls.agent + '?organizationId=' + this.accountService.getOrganizationId())
          .subscribe((res: any) => {
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    );
  }
  getUser(chatWith, chatType, page, limit, search) {
    return new Promise((resolve, reject) => {
        return this.http.get(Urls.getUser + chatWith + '/' + chatType + '?organizationId=' + this.accountService.getOrganizationId() +
          '&page=' + page + '&limit=' + limit + '&search=' + search)
          .subscribe((res: any) => {
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    );
  }

  assignAgent(handOffId, agentId) {
    return new Promise((resolve, reject) => {
        return this.http.put(Urls.assignAgent + handOffId + '?newAgentId=' + agentId, '')
          .subscribe((res: any) => {
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    );
  }

  checkUserIsAvailable(handoffId) {
    return new Promise((resolve, reject) => {
        return this.http.get(Urls.checkUserAvailable + handoffId)
          .subscribe((res: any) => {
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    );
  }



  getTranscriptData(data, id) {
    return new Promise((resolve, reject) => {
        return this.http.get(Urls.getTranscript + data + '/' + id)
          .subscribe((res: any) => {
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    );
  }

  initiateHandOff(id) {
    return new Promise((resolve, reject) => {
        return this.http.get(Urls.initiateHandoff + id  + '?organizationId=' + this.accountService.getOrganizationId())
          .subscribe((res: any) => {
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    );
  }
}
