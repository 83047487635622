import {environment} from '../../environments/environment';

export class Urls {
  static baseUrl = environment.apiUrl;
  static login = Urls.baseUrl + 'auth/login';
  static superAdminLogin = Urls.baseUrl + 'auth/loginAsSuperAdmin';
  static logout = Urls.baseUrl + 'auth/logout';
  static getUser = Urls.baseUrl + 'supportPanel/status/';
  static checkUserAvailable = Urls.baseUrl + 'handoff/checkAvailability/';
  static assignAgent = Urls.baseUrl + 'handoff/assignNewAgent/';
  static intent = Urls.baseUrl + 'handoff/getIntents/';
  static agent = Urls.baseUrl + 'handoff/getAgents/';
  static fileUpload = Urls.baseUrl + 'handoff/upload/file/';
  static getTranscript = Urls.baseUrl + 'transcript/';
  static initiateHandoff = Urls.baseUrl + 'handoff/initiate-handoff/';
  static forgotPassword = Urls.baseUrl + 'auth/forget-password';
  static setPassword = Urls.baseUrl + 'auth/';
  static resetPassword = Urls.baseUrl + 'auth/';
  static settings = Urls.baseUrl + 'bot-settings/';
  static user = Urls.baseUrl + 'user/';
  static webWidget = Urls.baseUrl + 'web-widget/';
  static lookAndFeel = Urls.webWidget + 'look-and-feel';
  static delay = Urls.webWidget + 'delay';
  static welcomeMessages = Urls.webWidget + 'welcome-messages';
  static getListUser = Urls.baseUrl + 'supportPanel/status/';
  static changePassword = Urls.baseUrl + 'auth/change-password';
  static userProfile = Urls.baseUrl + 'user/profile';
  static profilePicture = Urls.baseUrl + 'user/upload/profile-picture';

  // Analytics Urls | START
  static analytics = Urls.baseUrl + 'analytics/';
  static download = '/download';
  static topFirstIntent = Urls.analytics + 'top-first-intents';
  static topResolutionIntents = Urls.analytics + 'top-resolution-intents';
  static categoryAndStates = Urls.analytics + 'categoryAndState';
  static resolutionAndStates = Urls.analytics + 'resolutionAndState';
  static lastUrlClicked = Urls.analytics + 'lastUrlClicked';
  static initialPage = Urls.analytics + 'initialPage';
  static tagAnalytics = Urls.analytics + 'tag-analytics';
  static ageRange = Urls.analytics + 'age-range';
  static specificCustomTags = Urls.baseUrl + 'custom-tag/customTags';
  // Analytics Urls | END
  static moduleAccess = Urls.baseUrl + 'encapsulation/access';
  static customTags = Urls.baseUrl + 'custom-tag/';
}
