import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AccountService} from './account.service';
import {Urls} from '../utils/urls';

@Injectable({
  providedIn: 'root'
})
export class ModuleAccessService {
  constructor(private http: HttpClient, private accountService: AccountService) { }
  getModules(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.moduleAccess + '?organizationId=' + this.accountService.getOrganizationId()).subscribe((res: any) => {
        resolve(res.access);
      }, error => {
        reject(error);
      });
    });

  }

}
