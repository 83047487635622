import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Urls} from "../utils/urls";
import {AccountService} from "./account.service";
import {LookAndFeelModel} from "../models/web-widget.models";
import {Observable} from "rxjs";

@Injectable()
export class WebWidgetService {
  botLookAndFeel: LookAndFeelModel = {
    widget: {
      bot: {
        bubbleColour: '#18c2c4',
        fontColour: '#fff',
        fontStyle: 'Lato'
      },
      user: {
        bubbleColour: '#fff',
        fontColour: '#000',
        fontStyle: 'Lato'
      },
      quickReply: {
        bubbleColour: "#ddd",
        fontColour: "#444"
      },
      inputBackground: {
        bubbleColour: '#18c2c4',
        fontColour: '#000'
      },
      agent: {
        bubbleColour: '#18c2c4',
        fontColour: '#fff',
        fontStyle: 'Lato'
      },
      position: 'right',
      size: 5,
      chatBackgroundColour: '#F7F7F7',
      chatFontColour: '#fff',
      titleBackgroundColour: '#008183',
      titleFontColour: '#fff',
      sendButtonColor: '#015556',
      iconUrl: '',
      botFaceUrl: '',
      widgetType: '',
      bannerLeftUrl: '',
      bannerRightUrl: '',
    },
    botName: 'Human Pixel'
  };
  constructor(private http: HttpClient,
              private _authService: AccountService) {
  }

  getLookAndFeelConfig(): any {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.lookAndFeel + "?organizationId=" +
        this._authService.getOrganizationId()).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  setLookAndFeelConfig(data): any {
    return new Promise((resolve, reject) => {
      this.http.put(Urls.lookAndFeel + "?organizationId=" +
        this._authService.getOrganizationId(), data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getDelayConfig(): any {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.delay + "?organizationId=" +
        this._authService.getOrganizationId()).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  setDelayConfig(data): any {
    return new Promise((resolve, reject) => {
      this.http.put(Urls.delay + "?organizationId=" +
        this._authService.getOrganizationId(), data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getWelcomeConfig(): any {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.welcomeMessages + "?organizationId=" +
        this._authService.getOrganizationId()).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  setWelcomeConfig(data): any {
    return new Promise((resolve, reject) => {
      this.http.put(Urls.welcomeMessages + "?organizationId=" +
        this._authService.getOrganizationId(), data).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  resetLookAndFeelConfig(): any {
    return new Promise((resolve, reject) => {
      this.http.put(Urls.lookAndFeel + "/restore-default?organizationId=" +
        this._authService.getOrganizationId(), {}).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getWidgetType(): Observable<any> {
    return this.http.get(Urls.webWidget + 'widget-type');
  }
  uploadBanner(file, urlString, oldImageName: string): Observable<any> {
    if (oldImageName) {
      oldImageName = oldImageName.replace(/^.*[\\\/]/, '');
      return this.http.post(`${Urls.webWidget}upload/${urlString}?oldImageName=${oldImageName}&organizationId=${this._authService.getOrganizationId()}`, file);
    } else {
      return this.http.post(`${Urls.webWidget}upload/${urlString}?organizationId=${this._authService.getOrganizationId()}`, file);
    }
  }
}
