import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {routes} from "./app-routing.module";
import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {RouterModule} from "@angular/router";
import {MaterialModule} from "./material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "./utils/shared.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthGuard} from "./utils/authGuard";
import {TokenInterceptor} from "./utils/token.interceptor";
import {AccountService} from "./services/account.service";
import {HandoffService} from "./services/handoff.service";
import {MessagingService} from "./services/messaging.service";
import {environment} from "../environments/environment";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {WebWidgetService} from "./services/web-widget.service";
import { DialogComponent } from "./components/dialog/dialog.component";
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DialogComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MaterialModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxMaterialTimepickerModule
  ],
  providers: [
    AccountService,
    AuthGuard,
    HandoffService,
    MessagingService,
    WebWidgetService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  exports: [],
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent]
})
export class AppModule {
}
