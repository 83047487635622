import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import {Injectable} from "@angular/core";
import {AccountService} from "../services/account.service";
import {Observable, Subject} from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {
  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> {
    return this._userActionOccured.asObservable()
  };

  constructor(private router: Router, private accountService: AccountService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const token = this.accountService.getToken();
    const user = this.accountService.getUserName();
    const role=this.accountService.getUserRole();
    if (token && user) {
      if(role==='supportAgent'){
        let mainUrl=state.url.split('/');
        let url=mainUrl[mainUrl.length-1].split('?');
        if(url[0]==='analytics' || url[0]==='bot-settings' || url[0]==='web-widget'){
          this.router.navigate(['dashboard',"hand-off"], {
            queryParams: {returnUrl: state.url}
          });
          return false;
        }else {
          return true;
        }
      }else {
        return true;
      }
    } else {
      this.accountService
        .logout()
        .then(res => {
          this.router.navigate(["login"], {
            queryParams: {returnUrl: state.url}
          });
        })
        .catch(err => {
          this.router.navigate(["login"], {
            queryParams: {returnUrl: state.url}
          });
        });

      return false;
    }
  }
  notifyUserAction() {
    this._userActionOccured.next();
  }
}
