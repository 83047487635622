import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {LoginComponent} from "./components/login/login.component";
import {AuthGuard} from "./utils/authGuard";

export const routes: Routes = [
  {
    path: "dashboard",
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard]
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
 /* {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  },*/
  {
    path: "set/:id",
    component: LoginComponent
  },
  {
    path: "reset/:id",
    component: LoginComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
