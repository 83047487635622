import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {AccountService} from "../services/account.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public accountService: AccountService, public router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        authorization: `${this.accountService.getToken()}`
      }
    });
    return next.handle(req).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.accountService.logout();
              this.router.navigate(["login"]);
            }
          }
        }
      )
    );
  }
}
